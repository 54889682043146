












































































































import { defineComponent } from "@vue/composition-api";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "PerawatanAktivitas",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    AktivitasKomponenTag: () =>
      import("@/apps/aktivitas/components/AktivitasKomponenTag.vue"),
  },
  props: {
    aktivitas: { type: Object, required: true },
  },
  setup(props) {
    const aktivitas = props.aktivitas;
    let sortedDiagnosisList = [];
    const diagnosisList = aktivitas.aktDiagList;
    const diagLength = diagnosisList.length;
    const keterampilanLength = aktivitas.aktKetList.length;
    const pemeriksaanLength = aktivitas.aktPemeriksaanList.length;
    const tatalaksanaLength = aktivitas.aktTatalaksanaList.length;
    // Ordering untuk diagnosisList
    sortedDiagnosisList = diagnosisList.sort(
      (el1: Record<string, any>, el2: Record<string, any>) => {
        if (el1.utama && !el2.utama) return -1;
        if (!el1.utama && el2.utama) return 1;
        if (el1.diagnosisNama > el2.diagnosisNama) return 1;
        if (el1.diagnosisNama < el2.diagnosisNama) return -1;
        return 0;
      }
    );
    sortedDiagnosisList.forEach((aktDiag: Record<string, string>) => {
      aktDiag.nama = aktDiag.diagnosisNama;
    });

    aktivitas.aktPemeriksaanList.forEach((aktPem: Record<string, string>) => {
      aktPem.nama = aktPem.pemeriksaanpenunjangNama;
    });
    aktivitas.aktTatalaksanaList.forEach((aktPem: Record<string, string>) => {
      aktPem.nama = aktPem.tatalaksanaNama;
    });

    aktivitas.aktKetList.forEach((aktPem: Record<string, string>) => {
      aktPem.nama = aktPem.tindakanketerampilanNama;
    });

    return {
      // Data
      diagLength,
      keterampilanLength,
      // menuConfigs,
      pemeriksaanLength,
      sortedDiagnosisList,
      tatalaksanaLength,

      // Method
      toUserDateFormat,
    };
  },
});
